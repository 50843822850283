@import '../../../assets/variables';

.search-agent-item_container {
  display: flex;
  align-items: center;
  border-radius: 4px;

  @media (min-width: $md) {
    background-color: $white;
  }
}

.search-agent-item_link {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  padding: 20px;

  h5 {
    margin: 0 0 6px 0;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    text-transform: capitalize;
    color: $grey-4;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    color: $grey-4;
    opacity: 0.7;
  }
}

.search-agent-item_link:hover {
  color: $blue-1;
  opacity: 1;
  cursor: pointer;
}

@import '../../assets/variables.scss';

.finden-profile-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;
  float: left;
  font-size: 18px;
  line-height: 23px;

  & li {
    margin-bottom: 12px;
    position: relative;
    &.notification:after {
      top: -3px;
      right: -6px;
    }
    cursor: pointer;
    &.selected {
      font-weight: bold;
    }
    & a,
    & a:visited,
    & a:hover {
      color: $grey-3;
      text-decoration: none;
    }
  }

  &.top {
    text-align: left;
    float: none;
    display: flex;
    align-items: center;
    & > li {
      display: inline-block;
      margin-left: 30px;
      margin-bottom: 0;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.finden-sub-menu {
  display: none;
  list-style: none;
  margin: 0;
  padding: 10px 0;
  background-color: $white;
  position: absolute;
  box-shadow: 0 10px 26px $rgb-grey-4;
  top: 58px;
  right: -14px;
  border-radius: 6px;
  border-top: 2px solid $red-3;
  box-sizing: border-box;
  z-index: 3;

  & .divider {
    padding: 0 15px;
    & div {
      background-color: $grey-10;
      display: block;
      height: 1px;
      width: 100%;
    }
  }

  & > li {
    width: 280px;
    margin: 0;
    & > a {
      display: block;
      padding: 9px 20px;
    }
  }
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid $red-3;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: -20px;
    right: 22px;
    transform: rotate(-90deg);
  }

  @media screen and(min-width:$md) {
    .external {
      display: none;
    }
  }
}

li:hover > .finden-user-picture {
  border: 2px solid $white;
  box-shadow: 0 0 0 2px red;
}

li:hover > .finden-sub-menu {
  display: block;
}

.notification:after {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  background: $red-3;
  margin: 0;
  position: absolute;
  border-radius: 50% !important;
  overflow: hidden;
}

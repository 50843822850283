@import '../../assets/variables.scss';

.finden-user-picture {
  border-radius: 50%;
  background-color: $white;
  overflow: hidden;
  display: inline-block;
  box-sizing: content-box;
  border: 2px solid $white;
  box-shadow: 0 0 0 2px $white;
  margin-right: -2px;
  border: 2px solid $grey-1;

  & .user-profile-photo {
    width: 100%;
  }
}

@import '../../assets/variables.scss';

.finden-input-fieldset {
  margin: 0;
  padding: 0;
  border: none;
  position: relative;
  background: $white;
  border-radius: 4px;
  border: 1px solid $grey-5;
  margin-bottom: 15px;
  transition: 0.3s;

  & * {
    display: block;
    width: 100%;
  }

  &.with-error {
    border: 2px solid $red-5;
    background-color: $rgb-red-1;
    transition: 0.3s;
    margin-bottom: 35px;
  }
}

input.finden-input {
  position: relative;
  font-size: 16px;
  padding: 15px;
  line-height: 20px;
  border: none;
  border-radius: 4px;
  background-color: transparent !important;
  z-index: 2;
  outline: none;
  font-family: $sansPro;
}

input.finden-input + .finden-input-label {
  display: block;
  width: auto;
  position: absolute;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  top: 15px;
  left: 15px;
  color: $grey-1;
  opacity: 0.7;
  background: transparent;
  transition: 0.3s;
  z-index: 1;
  font-family: $sansPro;
}

input.finden-input:valid + .finden-input-label,
input.finden-input:focus + .finden-input-label {
  font-size: 12px;
  line-height: 28px;
  top: -13px;
  left: 11px;
  padding: 0 4px;
  background: linear-gradient(to top, transparent 0%, transparent 47%, $grey-12 48%, $grey-12 100%);
  transition: 0.3s;
  z-index: 1;
  opacity: 1;
}

.finden-input-error {
  position: absolute;
  font-size: 12px;
  text-align-last: left;
  color: $red-5;
  bottom: -30px;
  left: 15px;
  line-height: 28px;
}

.finden-input-fieldset {
  &.with-error {
    & input.finden-input:valid + .finden-input-label,
    & input.finden-input:focus + .finden-input-label {
      color: $red-5;
      background: linear-gradient(
        to top,
        transparent 0%,
        transparent 47%,
        $grey-12 48%,
        $grey-12 100%
      ) !important;
      transition: 0.3s;
    }
  }
}

fieldset.finden-input-fieldset.disabled {
  opacity: 50%;
  pointer-events: none;
}

@import '../../assets/_mixins.scss';
@import '../../assets/variables.scss';

.finden-button {
  border-radius: 4px;
  padding: 9px 20px;
  font-size: 14px;
  line-height: 18px;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  font-family: $sansPro;
  letter-spacing: 2px;

  &,
  &.neutral {
    color: $grey-4;
    background-color: $white;
    border: 1px solid $grey-6;
  }

  &.primary {
    @include linear-gradient(105deg, $red-4, $red-4, $red-3, $red-2, $red-1);
    color: $white;
    border: none;
  }

  &.full-width {
    width: 100%;
    display: block;
  }

  &.big {
    padding: 15px 0;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 20px;
    font-weight: bold;
    @include linear-gradient(262deg, $red-7, $red-6);
  }

  &.disabled,
  &:disabled {
    color: $white;
    background: $grey-16;
    cursor: default;
  }

  &:focus {
    outline: none;
  }
}

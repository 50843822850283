@import '../../assets/variables';

.finden-field {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &-placeholder,
  &-content {
    font-size: 16px;
    line-height: 28px;
  }
  &-title {
    width: 100%;
    font-weight: bold;
    opacity: 0.6;
  }
  &-edit {
    color: $red-3;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
  }
}

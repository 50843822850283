@import '../../assets/variables';

.finden-authorization-email-expired {
  & .finden-authorization-text {
    margin-bottom: 20px;
    @media screen and (min-width: $md) {
      margin-bottom: 30px;
    }
  }
}

$black: #000 !default;
$white: #fff !default;

$green-1: #008260 !default;
$green-2: #00906b !default;
$green-3: #00b084 !default;
$green-4: #00b184 !default;

$blue-1: #007991 !default;
$blueish-1: #62dcb7 !default;
$blueish-2: #00799133 !default;

$grey-1: #343434 !default;
$grey-2: #353535 !default;
$grey-3: #434343 !default;
$grey-4: #484848 !default;
$grey-5: #757575 !default;
$grey-6: #aaaaaa !default;
$grey-7: #acacac !default;
$grey-8: #c1c1c1 !default;
$grey-9: #c7c7c7 !default;
$grey-10: #e4e4e4 !default;
$grey-11: #e8eaed !default;
$grey-12: #f8f9fa !default;
$grey-13: #f9f9f9 !default;
$grey-14: #f1f1f1 !default;
$grey-15: #e9e9e9 !default;
$grey-16: #cbcbcb !default;
$grey-17: #c3c3c3 !default;
$grey-18: #bebebe !default;
$grey-19: #e3e3e3 !default;
$grey-20: #eeeeee !default;
$grey-21: #4848481a !default;

$red-1: #e6003c !default;
$red-2: #e60b37 !default;
$red-3: #e6272b !default;
$red-4: #e6461e !default;
$red-5: #b40f2d !default;
$red-6: #f2622d !default;
$red-7: #ee2943 !default;

$rgb-red-1: rgba(180, 15, 45, 0.05);

$rgb-grey-1: rgb(53, 53, 53) !default;
$rgb-grey-2: rgba(0, 0, 0, 0.16) !default;
$rgb-grey-3: rgba(0, 0, 0, 0.2) !default;
$rgb-grey-4: rgba(0, 0, 0, 0.3) !default;
$rgb-grey-5: rgba(52, 52, 52, 0.2) !default;
$rgb-grey-6: rgba(94, 94, 94, 0.4) !default;
$rgb-grey-7: rgba(52, 52, 52, 0.1) !default;

$blue_lagoon: #007991;
$silver_chalice: #aaaaaa;
$alabaster3: #f9f9f9;
$tapa2: #757575;
$tapa: #777777;
$aquamarine: #78ffd6;
$viking: #62dcb7;

$shiraz: rgb(180, 15, 45);
$mercury: #e4e4e4;
$tuatara: #343434;
$gravel: #484848;
$color_chrome_white: #dcedc8 !default;
$color_apple: #689f38 !default;

// device sizes
$xs: 475px;
$sm: 576px;
$md: 768px;
$lmd: 845px;
$lg: 992px;
$shd: 1280px; //small HD width (1280 x 720 resolution)
$lhd: 1366px; //large HD width (1366 x 768 resolution)
$xhd: 1680px; //extra large HD width (1680 x 1050 resolition)
$xlhd: 1440px; //large HD width (1440 x 900 resolution)

$headerBigHeight: 80px;
$headerSmallHeight: 60px;

$sansPro: var(--font-info);
$serifPro: var(--font-text);

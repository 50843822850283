@import '../../assets/variables';

.about-section {
  padding-top: 25px;
  padding-bottom: 45px;
  max-width: 1440px;
  margin: 0 auto;
  & article {
    max-width: 66.6667%;
    margin: 0 auto;
  }
}

.entry-header {
  margin-bottom: 1.5rem;
}

.about-section-title {
  padding-top: 1.5rem;
}

@media (max-width: $md) {
  .about-section {
    & h2 {
      font-size: 1.7rem;
    }

    & a {
      word-break: break-all;
    }
  }
}

@import '../../assets/variables';

.search-agent-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    border-bottom: 1px solid $grey-10;

    &:last-child {
      border-bottom: none;

      @media (min-width: $lg) {
        border-bottom: 1px solid $grey-17;
      }
    }

    @media (min-width: $lg) {
      border: 1px solid $grey-17;
      border-radius: 4px;
      margin-bottom: 15px;
    }
  }
}

@import '../../assets/variables';

@keyframes placeHolderShimmer {
  0% {
    background-position: -100px 0;
  }
  100% {
    background-position: 600px 0;
  }
}

.bookmark-loader-item {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0 20px 20px 20px;
  @media (min-width: $lg) {
    padding: 0;
  }

  &-image,
  &-title,
  &-description,
  &-location,
  &-button {
    animation: placeHolderShimmer 1.5s linear 0s infinite forwards;
    background: linear-gradient(to right, $grey-19 8%, $grey-20 18%, $grey-19 33%);
    background-size: 800px 104px;
  }

  &-image {
    height: 70px;
    width: 70px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (min-width: $lg) {
      width: 200px;
      height: 150px;
    }
  }

  &-body {
    width: 100%;
    max-width: 200px;
    margin-left: 10px;
    margin-right: 15px;
    word-break: break-all;
    hyphens: auto;
    overflow: hidden;
    @media (min-width: $lg) {
      max-width: 400px;
      min-width: 240px;
      padding: 20px 55px 25px 25px;
      margin: 0;
    }
  }

  &-title {
    height: 44px;
    margin-bottom: 6px;
    width: 100%;
  }

  &-description {
    height: 23px;
    margin-bottom: 6px;
    width: 60%;
  }

  &-location {
    height: 20px;
    width: 75%;
  }

  &-button {
    width: 40px;
    height: 40px;

    @media (min-width: $lg) {
      margin: 10px;
    }
  }
}

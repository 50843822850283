@import '../../../assets/variables';

.bookmark-item {
  border-bottom: 1px solid $grey-10;
  padding: 0 20px 20px 20px;
  margin-bottom: 15px;
  width: auto;

  @media (min-width: $sm) {
    border-bottom: none;
  }

  @media (min-width: $lg) {
    width: 100%;
  }

  &:last-child {
    border-bottom: none;
  }
}

.bookmark-item-link {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  &:last-child {
    @media (min-width: $lg) {
      border-bottom: 1px solid $grey-17;
    }
  }

  @media (min-width: $lg) {
    flex-direction: row;
  }

  @media (min-width: $sm) {
    border: 1px solid $grey-17;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 0;
  }
}

.bookmark-item-image-wrapper {
  border-radius: 4px 0 0 0;
  padding: 0;
  width: 100%;
  max-width: unset;
  min-width: unset;
  display: flex;
  background-color: $white;

  @media (min-width: $lg) {
    min-width: 200px;
    max-width: 200px;
    border-right: 1px solid $grey-17;
    border-radius: 4px 0 0 4px;
    padding: 0;
  }
}

.bookmark-item-image-container {
  border: 1px solid $grey-17;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px 2px 2px 2px;
  min-width: unset;
  max-width: unset;
  width: 100%;
  height: 100%;
  min-height: unset;
  max-height: unset;

  &.realestate-image-container {
    background: $grey-18;
  }

  & .image-placeholder {
    width: 100%;
    background-color: $white;
  }

  @media (min-width: $lg) {
    border: none;
    min-width: 200px;
    max-width: 200px;
    min-height: 150px;
    max-height: 150px;
  }
}

.bookmark-item-image-jobs {
  padding: 0 5px;
  max-width: 100%;

  @media (min-width: $sm) {
    width: auto;
  }
  @media (min-width: $lg) {
    width: 100px;
  }
}

.bookmark-item-delete-button-container {
  position: absolute;
  right: 0;

  @media (min-width: $lg) {
    position: unset;
  }
}

@import '../../assets/variables';

.finden-profile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 0;

  @media screen and (min-width: $md) {
    padding: 70px 0;
  }
}

// content
.finden-profile-content {
  width: 100%;
  max-width: 475px;

  @media screen and (min-width: $lg) {
    margin: 0 0 0 70px;
    min-width: 475px;
    max-width: 720px;
  }

  & h2 {
    margin-bottom: 30px;
    color: $grey-4;
    font-size: 30px;
    line-height: 38px;
    font-weight: bold;
    margin-top: 0;
  }
}

@import '../../assets/variables';

.bookmarks-list-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0 20px;

  @media (min-width: $lg) {
    padding: 0;
  }
}

.bookmarks-list-title {
  font-size: 24px;
  font-weight: bold;
  color: $grey-4;
}

.booksmarks-list-results {
  font-size: 16px;
  color: $grey-4;
}

.bookmarks-list-unordered-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bookmarks-no-items-message {
  font-size: 24px;
  height: 200px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

@import './_variables.scss';

html {
  height: 100%;
}

#root {
  height: 100vh;
}

body {
  background: $grey-12;
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-y: auto;
  color: $grey-3 !important;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

.finden-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.btn.btn-success {
  background-color: $green-4;
  border-radius: 4px;
  border: 1px solid $green-4;
  &:hover,
  &:not(:disabled):not(.disabled):active {
    background-color: $green-1;
    border: 1px solid $green-1;
    color: $white;
  }
}

.body {
  font-family: $sansPro;
  height: 100%;
}

body.overlayBodyOpen {
  overflow: hidden; //this class is active only when an overlay window is open, this is to prevent scrolling
  -webkit-overflow-scrolling: touch;
}

a:hover {
  cursor: pointer;
}

img {
  color: transparent;
  font-size: 0px;
}

.btn-success svg {
  fill: $white;
}

.line-separator {
  height: 1px;
  width: 100%;
  background-color: $grey-10;
  margin: 25px 0;
}

.h1,
h1 {
  font-size: 2.5rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

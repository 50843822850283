@import '../../../../assets/variables';
@import '../../../../assets/mixins';

.bookmark-item-body {
  width: 100%;
  max-width: 240px;
  margin: 0 10px;
  word-break: break-all;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  overflow: hidden;
  padding: 10px 0;
  @media (min-width: $lg) {
    max-width: 400px;
    min-width: 240px;
    padding: 20px 55px 25px 25px;
    margin: 0;
  }
}

.bookmark-item-title {
  color: rgba($grey-1, 0.7);
  font-size: 17px;
  margin-bottom: 6px;

  &.bookmark-item-title-realestate {
    @include line-clamp-mx(2);
  }

  &.bookmark-item-title-jobs {
    @include line-clamp-mx(1);
  }
}

.bookmark-item-description {
  font-size: 18px;
  font-weight: bold;
  color: $grey-4;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;

  &.bookmark-item-description-jobs {
    @include line-clamp-mx(2);
  }
}

.bookmark-item-detail {
  color: $grey-4;
  font-size: 16px;
}

.bookmark-item-location {
  display: flex;
  flex-direction: row;
}

.bookmark-item-pin-location {
  min-height: 18px;
  min-width: 18px;
  max-height: 18px;
  max-width: 18px;
}

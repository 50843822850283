.finden-button-fieldset {
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & button {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }

  &.end {
    justify-content: flex-end;
    & button {
      margin-right: 0;
      margin-left: 15px;
      &:first-child {
        margin-right: 0;
      }
    }
  }
}

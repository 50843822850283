@import '../../assets/variables';

.login-link {
  text-decoration: none;
  color: $grey-1;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}
